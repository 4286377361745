import React from "react";

import Meta from "./helmet";
import Navbar from "./navbar";
import Footer from "./footer";

import "../styles/style.scss";

class ContentLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Meta />
        <Navbar navClasses="gradientBg contentNavbar" />
        {children}
        <Footer />
      </div>
    );
  }
}

export default ContentLayout;
