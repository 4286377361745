import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { withNamespaces } from "react-i18next";

import "../../i18n";
import ContentLayout from "../../components/contentLayout";


const VeritableNewsPage = ({ t, data }) => {
  return (
    <ContentLayout>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content">
                <h1 className="title is-2 center">{t("reader")}</h1>
                <h4 className="subtitle center">({t("reader-subtitle")})</h4>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <Img fixed={data.image1.childImageSharp.fixed} />
            </div>
          </div>
          <div className="columns is-multiline is-tablet">
            <div className="column is-10-desktop is-offset-1-desktop is-half-widescreen is-offset-3-widescreen">
              <div className="content">
                <p>{t("reader-paragraph-1")}</p>
                <p>{t("reader-paragraph-2")}</p>
                <p>{t("reader-paragraph-3")}</p>
                <p>
                  {t("reader-paragraph-4-1")}
                  <a
                    href="https://github.com/ceshine/textrank_demo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    github.com/ceshine/textrank_demo
                  </a>{" "}
                  {t("reader-paragraph-4-2")}
                </p>
                <p>{t("technology-used")}</p>
                <ul>
                  <li>
                    A.I. (NLP) Algorithms
                    <ul className="inner">
                      <li>
                        (PyTorch){" "}
                        <a
                          href="https://github.com/summanlp/textrank"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          summanlp/textrank
                        </a>{" "}
                        (Base TextRank Implementation)
                      </li>
                      <li>
                        (PyTorch){" "}
                        <a
                          href="https://github.com/ceshine/sentence-transformers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sentence Transformers
                        </a>
                      </li>
                      <li>
                        (PyTorch){" "}
                        <a
                          href="https://github.com/facebookresearch/LASER"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LASER
                        </a>
                      </li>
                      <li>
                        (Tensorflow){" "}
                        <a
                          href="https://tfhub.dev/google/universal-sentence-encoder-multilingual/1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Multilingual Universal Sentence Encoder
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/ceshine/sentence-transformers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Spacy
                        </a>{" "}
                        (English sentence segmentation and named entity
                        recognition)
                      </li>
                      <li>
                        <a
                          href="https://nlp.baidu.com/homepage/coreTechnology/arith?SubTitle=NLP%E5%9F%BA%E7%A1%80%E7%AE%97%E6%B3%95"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Baidu NLP API
                        </a>{" "}
                        (Chinese word segmentation)
                      </li>
                      <li>
                        <a
                          href="https://github.com/taishi-i/nagisa"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          nagisa
                        </a>{" "}
                        (Japanese word segmentation)
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    Interface
                    <ul className="inner">
                      <li>
                        Web Interface: Pure{" "}
                        <a
                          href="https://reactjs.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          React
                        </a>
                      </li>
                      <li>
                        Backend API Server:{" "}
                        <a
                          href="https://github.com/tiangolo/fastapi"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FastAPI
                        </a>
                      </li>
                      <li>
                        Backend Server Infrastructure:{" "}
                        <a
                          href="https://cloud.google.com/run/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cloud Run (Google Cloud)
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Web Content Extraction:{" "}
                    <a
                      href="https://github.com/ceshine/python-readability"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      python-readability
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <div className="box">
                <Img fixed={data.image2.childImageSharp.fixed} />
                <p className="center">{t("reader-caption-annotations")}</p>
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <div className="box">
                <Img
                  className="center"
                  fixed={data.image3.childImageSharp.fixed}
                />
                <p className="center">{t("reader-caption-chinese")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContentLayout>
  );
};

export default withNamespaces(["projects", "navbar"], {})(VeritableNewsPage);

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projects-reader-cover.png" }) {
      childImageSharp {
        fixed(width: 750) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "projects-reader-annotations.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image3: file(relativePath: { eq: "projects-reader-chinese.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
